import React, { useEffect, useRef, useState } from 'react'
import '../Styles/Contact_us.css'
import { Container } from 'react-bootstrap';

import ContactUs from "./Contactus/ContactUs"
import Aos from 'aos'
import ChatWidget from './ChatWidget';

import emailjs from "@emailjs/browser";
const Contact_us = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("")

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event) {
      alert("let's go to Contact");
    }
    else {
      alert("input field is required")
    }
  };
  useEffect(() => {
    Aos.init()
    window.scroll(0, 0)
  })

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_kkym0ri",
        "template_eyldldp",
        form.current,
        "55NAIkjQQtvtM-BFL"
      )
      .then(
        (result) => {
          console.log(result.text);
          window.location.assign("/submit")
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (

    <div classname="main-contact-us-page  _blank " >

      <div className="headerServiceMain">
        <header className="servicenavbar">
          <div className="bothside bothside1 ">
            <p className="display-5">Contact us </p>
          </div>
          <div className="bothside bothside2">
            <span>Home</span> / <span ><u>Contact us</u></span>
          </div>
        </header>
        <figure className="figuretagService">
          <img src="/Assets/aboutusnav.jpg" alt="" />
          
        </figure>
      </div>

      <Container>
        <div className="container-header-we-do m-2">
          <div className="display-6 P-2 text-center"> Get in Touch  </div>
          <div class="separator  ">
            <span>
              <i class="fa fa-circle">*--*</i>
            </span>
          </div>
        </div>


        <div className='text-center '>
          <p className='contact-content'>Ready to transform your space? Contact us today and let’s create something extraordinary together.</p>
        </div>
      </Container>


      {/* <Container>

        <div class="mt-4 mb-4" data-aos="fade-right" data-aos-duration="3000">

          <div className="row" >

            <div className="col-md-6 mb-3">

              <form action="" className="form-heading" onSubmit={handleSubmit}>
                <h4>Let's Talk</h4>

                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  placeholder="Enter your name..."
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  placeholder="Enter your Phone number...."
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />

                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  placeholder="Enter your email...."
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <label htmlFor="message">Message</label>
                <input
                  type="text"
                  placeholder="Directly message us..."
                  id="message"
                  name="message"
                />{" "}
                <br />

                <button type="submit">Submit</button>

              </form>

            </div>

            <div className="col-md-6" data-aos="zoom-in" data-aos-duration="3000">
              <img src="/Assets/location.jpg" alt="" className='form-img' />
            </div>

          </div>

        </div>

      </Container>


      <Container className='mb-4'>

        <div id='background' >

          <div className='row d-flex justify-content-evenly' data-aos="zoom-in" data-aos-duration="3000">
         
            <div className='col-md-5 mb-2'>

              <div className='border'>
                <p className='fs-5'><FaPhoneVolume /></p>
                <p className='fs-5'>#7795841205</p>
              </div>

            </div>

            <div className='col-md-5 mb-2'>

              <div className='border'>
                <p className='fs-5'><MdEmail /></p>
                <p className='fs-5'>youremail123@gmail.com</p>
              </div>

            </div>

          </div>

        </div>
      </Container> */}

      <ContactUs />
      {/* <ChatWidget /> */}


    </div>
  )
}

export default Contact_us
