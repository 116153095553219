import  {React, useRef } from 'react';


import emailjs from "@emailjs/browser";


const ContactForm = () => {

    const form = useRef();
const sendEmail = (e) => {
  e.preventDefault();
  emailjs
    .sendForm(
      "service_htluhco",
      "template_j6g8gfh",
      form.current,
      "kkLond0sXdwc_lhHo"
    )
    .then(
      (result) => {
        console.log(result.text);
        window.location.assign("/")
      },
      (error) => {
        console.log(error.text);
      }
    );
};
  
    return (
        <div className="contact-form    col-md-4" data-aos="zoom-in" data-aos-duration="2000" >
            <h3>Send Message</h3>
            <form ref={form} onSubmit={sendEmail}>
                <input type="text"  name="user_name" placeholder="Full Name" required />
                <input type="email"  name="user_email" placeholder="Email" required/>
                <input type="text"  name="user_phone" placeholder="PhoneNumber" required />
                <textarea rows="4" cols="50" name="user_message"  placeholder="Type your Message..." required></textarea>
                <button type="submit">Send</button>
            </form>
        </div>
    );
};

export default ContactForm;
