// import React, { useState } from 'react';
// import '../Styles/ChatWidget.css';


// const ChatWidget = () => {

//   const [messages, setMessages] = useState([]);
//   const [inputValue, setInputValue] = useState('');

// //
//   const [isOpen, setIsOpen] = useState(false);

//   const handleToggle = () => {
//     setIsOpen(!isOpen);
//   };



// const handleMessageSend = () => {
//   if (inputValue.trim() === '') return; 
//   const newMessage = {
//     text: inputValue,
//     timestamp: new Date().getTime(),
//     sender: 'user' 
//   };

//   setMessages([...messages, newMessage]);
//   setInputValue('');
// };


//   return (

//     <div className="chat-widget">

//       <button className="chat-button" onClick={handleToggle}>
//         Let's Chat!
//       </button>

//       { isOpen && (
//         <div className="chat-box">

//           <div className="chat-header">
//               <div>
//                 <h4>Let's Chat!</h4>
//               </div>

//               <div>        
//                 <button className="close-button" onClick={handleToggle}>X</button>
//               </div>  
//           </div>


//       <div className="chat-content">

//       <div className="message-container">

//         {messages.map((message, index) => (
//           <div key={index} className={`message ${message.sender}`}>
//             {message.text}
//           </div>
//         ))}

//       </div>


//       <div className="input-container">

//         <input
//           type="text"
//           value={inputValue}
//           onChange={(e) => setInputValue(e.target.value)}
//           placeholder="Type your message..."
//         />

//         <button onClick={handleMessageSend}>Send</button>

//       </div>

//     </div>

//   </div>
//   )}

//   </div>

//   );
// };

// export default ChatWidget;

import '../Styles/ChatWidget.css';


import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// import '../Styles/whatsapp-button.css'; // Create a separate CSS file for styling

const ChatWidget = () => {

  const phoneNumber = 919380399336
  const whatsappMessage = encodeURIComponent('Hello, I am interested in your services.');

  const handleClick = () => {
    window.open(`https://wa.me/${phoneNumber}?text=${whatsappMessage}`, '_blank');
  };

  return (
    <button className="whatsapp-button" onClick={handleClick}>
      <img src="/Assets/whatsapp.png" className="whatsapp-icon" />
    </button>
  );
};

export default ChatWidget;