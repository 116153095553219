import React, { useEffect, useState } from 'react'
import '../Styles/Gallery.css'

import { Col, Container, Row } from 'react-bootstrap';

import Aos from 'aos';
import GData from '../Data/GData';

const Gallery = () => {
    const [Category, setCategory] = useState("")

    const [CategoryData, setCategoryData] = useState(GData)
    useEffect(() => {
        if (Category) {
            const filterdata = GData.filter((ele) => ele.type === Category)
            setCategoryData(filterdata)

        }
        if (Category === "all") {
            setCategoryData(GData)
        }
    }, [Category])

    // console.log("CategoryData", CategoryData);

    function clicker(e) {
        // console.log(e);
        // if (e.target.style.backgroundColor === "black") {
        //     e.target.style.backgroundColor = "transparent"
        // } else {
        //     e.target.style.backgroundColor = "black"
        // }

    }



    useEffect(() => {
        Aos.init();
        window.scroll(0, 0)
    }, [])


    return (

        <div>

            <div className="headerServiceMain">
                <header className="servicenavbar">
                    <div className="bothside bothside1 ">
                        <p className="display-5">Gallery </p>
                    </div>
                    <div className="bothside bothside2">
                        <span>Home</span> / <span >Gallery</span>
                    </div>
                </header>
                <figure className="figuretagService">
                    <img src="/Assets/Servicenavbar.jpg" alt="" />
                </figure>
            </div>

            <div className='navgallery-navbar'>
                <span onClick={(e) => { setCategory("all"); clicker(e) }}>All</span>
                <span onClick={(e) => { setCategory("OFFICE"); clicker(e) }}>OFFICE </span>

                <span onClick={(e) => { setCategory("RESIDENTIAL"); clicker(e) }}>RESIDENTIAL</span>

                <span onClick={(e) => { setCategory("HOSPITALY"); clicker(e) }}>HOSPITALITY</span>

            </div>


            <Container >

                <Row md={3} className=" mobile-function-res  " data-aos="zoom-in" data-aos-duration="3000">

                    {/* <Col className=" m-0 p-0 imagehover hovercontent1">
                        <img src="/Assets/gallery1.jpg" alt="gallery1" className='img' />
                    </Col> */}

                    {/* {CategoryData.map((items) => (
                        <Col key={items.id} className=" m-0 p-0 imagehover hovercontent1">
                            <img src={items.image} alt="gallery1" className='img' />
                        </Col>
                    ))} */}


                    {CategoryData.map((items) => (
                        <Col key={items.id} className=" gap-1 overcontent1  ">
                            <div className="image-container">
                                <img src={items.image} alt="gallery1" className='imgcontroll ' />
                                {/* <div className="overlay">
                                    <div className="text">{items.details}</div>
                                </div> */}
                            </div>
                        </Col>
                    ))}

                    {/* 
                    <Col className=" m-0 p-0 imagehover hovercontent2">
                        <img src="/Assets/gallery2.jpg" alt="gallery2" className='img' />
                    </Col>


                    <Col className=" m-0 p-0 imagehover hovercontent3">
                        <img src="/Assets/gallery3.jpg" alt="gallery3" className='img' />
                    </Col>


                    <Col className=" m-0 p-0 imagehover hovercontent4">
                        <img src="/Assets/gallery4.jpg" alt="gallery4" className='img' />
                    </Col>


                    <Col className=" m-0 p-0 imagehover hovercontent5">
                        <img src="/Assets/gallery5.jpg" alt="gallery5" className='img' />
                    </Col>


                    <Col className=" m-0 p-0 imagehover hovercontent6">
                        <img src="/Assets/gallery6.jpg" alt="gallery6" className='img' />
                    </Col>


                    <Col className=" m-0 p-0 imagehover hovercontent7">
                        <img src="/Assets/gallery8.jpg" alt="gallery8" className='img' />
                    </Col>


                    <Col className=" m-0 p-0 imagehover hovercontent8">
                        <img src="/Assets/gallery7.jpg" alt="gallery7" className='img' />
                    </Col> */}

                </Row>

            </Container>

        </div>

    )
}

export default Gallery;

