import React, { useEffect } from "react";
import "../Styles/Home.css";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OurProcess from "./HomeComponents/OurProcess";
import OurProjects from "./HomeComponents/OurProjects";
import KnowAboutMore from "./HomeComponents/KnowAboutMore";
import CustomerSays from "./HomeComponents/CustomerSays";
import Aos from "aos";


const Home = () => {
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });

  return (
    <div className="fontallsame">
      
      <div className="carousel-main-div" data-aos="zoom-in" data-aos-duration="2000">
        {/* Fade Carousel */}
      <Container>

        <Carousel fade  >
          <Carousel.Item>
            <img
              src="../Assets/cardmain2.jpg"
              alt="interior1"
              className="slider-image"
            />
            <Carousel.Caption className="carousel-header-text">
              <h1 className=" display-3" style={{ color: "white" }}>Office Design</h1>
              <p className="interior-txt display-6" style={{ color: "white" }}>Designs that make a statement</p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src="../Assets/cardmain3.jpg"
              alt="interior2"
              className="slider-image"
            />
            <Carousel.Caption className="carousel-header-text">
              <h1 className="display-3" style={{ color: "white" }}> Hospitality Design</h1>
              <p className="interior-txt  display-6" style={{ color: "white" }}>Designs that steal the spotlight </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              src="../Assets/cardmain1.jpg"
              alt="interior3"
              className="slider-image"
            />
            <Carousel.Caption className="carousel-header-text" >
              <h1 className="display-3" style={{ color: "white" }}>Residential Design</h1>
              <p className="interior-txt  display-6" style={{ color: "white" }}>Crafting homes that reflect your personality  </p>
            </Carousel.Caption>
          </Carousel.Item>

          {/* <Carousel.Item>
            <img
              src="/Assets/gd-h1.jpg"
              alt="interior3"
              className="slider-image"
            />
            <Carousel.Caption className="carousel-header-text">
              <h1 className="display-3" style={{ color: "white" }}>Custom Design</h1>
              <p className="interior-txt  display-6" style={{ color: "white" }}>Where imagination meets reality</p>
            </Carousel.Caption>
          </Carousel.Item> */}
        </Carousel>
        
     </Container>

      </div>

      {/* About Us */}

      <div className="about-us-welcome carousel-main-div" data-aos="fade-up" data-aos-duration="2000">

        <Container>

          <p className="display-6">Welcome to Dhavanesha Interiors</p>
          
          <p className=" Welcomepage-para  "> Where we bring your interior design dreams to life. With a rich history
            dating back to 1997 and now in our third generation, our commitment to excellence and innovation
            in the world of interiors remains unparalleled. Our Story Founded in 1997, Dhavanesha Interiors has been a
            cornerstone in the interiors industry. From humble beginnings to becoming a leader in design and
            craftsmanship, our journey has been marked by a dedication to quality and customer satisfaction. As
            a third-generation family business, we have inherited a legacy of passion and expertise in
            transforming spaces.
          </p>



        </Container>

      </div>


      {/* Wht we do */}

      <Container >
        <div className="container-header-we-do ">
          <div className="display-6 text-center">What We Do</div>
          <div class="separator  ">
            <span>
              <i class="fa fa-circle">*--*</i>
            </span>
          </div>
        </div>

        <div className="maincard-controller">
          <div style={{ width: "20rem" }} data-aos="fade-down" data-aos-duration="2000">
            <Card.Body>
              <Card.Title className="text-md-start ">
                <p><b className="card-title-color  ">Residential</b> Design</p>
              </Card.Title>
              <Card.Text className=" text-md-start opacity-50">
                Designing a home is an art that combines functionality, aesthetics, and personal touch. We believe
                that every home should tell a story (your story). Our goal is to create spaces that are as unique as
                you are.
                <div>
                  &nbsp;
                </div>


              </Card.Text>
            </Card.Body>

            <Card.Img variant="bottom" className="img-card-main-type" src="../Assets/cardmain1.jpg" />
          </div>
          <div style={{ width: "20rem" }} data-aos="fade-up" data-aos-duration="2000">
            <Card.Body>
              <Card.Title className="text-md-start ">
                <p><b className="card-title-color  ">Office </b> Design</p>
              </Card.Title>
              <Card.Text className=" text-md-start opacity-50">
                A well-designed office space is more than just aesthetically pleasing. It impacts employee
                productivity, creativity, and overall well-being. Thoughtful office design can enhance collaboration,
                reduce stress, and reflect your company’s culture.
              </Card.Text>
            </Card.Body>

            <Card.Img variant="bottom" className="img-card-main-type" src="../Assets/office2.jpg" />
          </div>
          <div style={{ width: "20rem" }} data-aos="fade-down" data-aos-duration="2000">
            <Card.Body>
              <Card.Title className="text-md-start ">
                <p><b className="card-title-color  ">Hospitality </b> Design</p>
              </Card.Title>
              <Card.Text className=" text-md-start opacity-50">
                In the hospitality industry, first impressions are everything. Our designs blend functionality with
                elegance, ensuring that your space is not only beautiful but also practical. We focus on creating
                environments that leave a lasting impression on your guests.
              </Card.Text>
            </Card.Body>

            <Card.Img variant="bottom" className="img-card-main-type" src="../Assets/cardmain3.jpg" />
          </div>
        </div>
        <KnowAboutMore />
      </Container>



      {/* Cards */}
      <OurProcess />

      <OurProjects />

      <CustomerSays />




    </div >
  );
};

export default Home;
