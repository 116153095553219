import React from "react";
import "./CustomerSays.css";
import "./OurProcess.css";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import { Container } from 'react-bootstrap';
import { Container, Carousel } from 'react-bootstrap';
import ReactReadMoreReadLess from "react-read-more-read-less";

// import { FaRegStar } from "react-icons/fa";

import { ImQuotesRight } from "react-icons/im";

import { FaStar } from "react-icons/fa";

import OwlCarousel from 'react-owl-carousel'

const CustomerSays = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };


    // const text1 = "I am delighted to share my experience with Dhavanesha Interiors, who recently transformed my flat into a stunning living space. From conceptualization to execution, their team demonstrated exceptional professionalism and creativity. Every detail was meticulously crafted, reflecting their commitment to quality and customer satisfaction. I commend Dhavanesha Interiors for their expertise in blending functionality with aesthetic appeal, creating a space that truly feels like home. Their dedication to understanding my preferences and translating them into reality exceeded my expectations. Thank you, Dhavanesha Interiors, for your outstanding work and for making this entire process seamless and enjoyable. I would highly recommend their services to anyone seeking top-notch interior design solutions."
    // const text2 = "We are immensely grateful to Dhavanesha Interiors for their exceptional work in transforming Ocean INN restaurant into a space that reflects our vision perfectly. Their attention to detail, creative insights, and dedication to quality were evident throughout the project. From conceptualization to execution, they ensured every aspect of the interiors resonated with our brand ethos and enhanced the dining experience for our guests. Working with Dhavanesha Interiors was a pleasure, and we highly recommend their services to anyone seeking innovative and professional interior design solutions."
    // const text3 = "Transforming our home with Dhavanesha Interiors demonstrated exceptional professionalism throughout the entire project. They listened attentively to my ideas and preferences, offering creative solutions that perfectly complemented my vision. From the selection of materials to the final execution, every step was carried out with precision and care. I am particularly impressed with their ability to balance aesthetics with practicality, creating a space that is both stylish and comfortable. Their commitment to quality and customer satisfaction is evident in the impeccable finish of their work. I highly recommend Dhavanesha Interiors to anyone looking for top-notch interior design services. Thank you for making my home a place I look forward to returning to every day."
    // const text4 = "I am thrilled to share my experience working with Dhavanesha Interiors, who transformed my flat, into a space that surpasses my dreams. Their team demonstrated  skill and dedication throughout the entire project. From the initial design phase to the final touches, Dhavanesha Interiors exhibited professionalism and creativity. They paid meticulous attention to detail, ensuring that every aspect of the interior design was executed flawlessly.  I am particularly impressed with their ability to blend functionality with aesthetic appeal, creating a home that is both practical and beautiful. Their commitment to quality and their understanding of my preferences made the entire process smooth and enjoyable. I wholeheartedly recommend Dhavanesha Interiors to anyone looking for outstanding interior design services. Thank you for turning my flat into a place I am proud to call home."

    return (
        <div className="CustomerSays-main-div overflow-hidden">

            <div className="customersayinner customer1">

                <div className="customer1inner customer1main1 customer-s">

                    <div className="display-6 pt-3 text-center">Customer Says</div>
                        <div class="separator" id="separator-home">
                            <span>
                             <i class="fa fa-circle">*--*</i>
                            </span>
                        </div>
                     </div>
                  

                
                {/* <div className="customer1inner customer1main2">
                    <Carousel
                        responsive={responsive}
                        showDots={true}
                        infinite={true}
                        autoPlay={"mobile" ? true : false}
                        autoPlaySpeed={5000}

                        removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
                    >
                        <div className="divcarousel-inner" data-aos="fade-up" data-aos-duration="2000">

                            <p className="h5">Shena,  Samhita Rainbow MTB</p>
                          
                            <ReactReadMoreReadLess
                                charLimit={120}
                                readMoreText={"Read more ▼"}
                                readLessText={"Read less ▲"}
                                readMoreClassName="readMore"
                                readLessClassName="readLess"
                            >
                                {text1}
                            </ReactReadMoreReadLess>
                        </div>


                        <div className="divcarousel-inner" data-aos="fade-down" data-aos-duration="2000">

                            <p className="h5">Rajesh Owner of Ocean INN Hotel </p>
                           <ReactReadMoreReadLess
                                charLimit={120}
                                readMoreText={"Read more ▼"}
                                readLessText={"Read less ▲"}
                                readMoreClassName="readMore"
                                readLessClassName="readLess"
                            >
                                {text2}
                            </ReactReadMoreReadLess>
                        </div>


                        <div className="divcarousel-inner" data-aos="fade-up" data-aos-duration="2000">

                            <p className="h5">Senthil, Vaswani</p>
                            <ReactReadMoreReadLess
                                charLimit={120}
                                readMoreText={"Read more ▼"}
                                readLessText={"Read less ▲"}
                                readMoreClassName="readMore"
                                readLessClassName="readLess"
                            >
                                {text3}
                            </ReactReadMoreReadLess>
                        </div>


                        <div className="divcarousel-inner" data-aos="fade-down" data-aos-duration="2000">

                            <p className="h5">Lakshmi, Ranga apartment, Domlur</p>
                            <ReactReadMoreReadLess
                                charLimit={150}
                                readMoreText={"Read more ▼"}
                                readLessText={"Read less ▲"}
                                readMoreClassName="readMore"
                                readLessClassName="readLess"

                            >
                                {text4}
                            </ReactReadMoreReadLess >
                        </div>
                    </Carousel>
                </div> */}

<section>
                {/* <img src="../Assets/contactus1.png" alt="" className='setion-img' /> */}
               
                {/* <Container >
                    <div class='row mt-3'>
                        
                        <div class="col-md-4" >
                            <div class="contact-bx" data-aos="flip-left" data-aos-duration="3000">
                                <img src="../Assets/testimony1.jpg" alt="Location" />
                                <h3 class="sect-title-3">Lakshmi Narayanan</h3>
                                <div className="star-quotes-group d-flex justify-content-between">
                                    <div className="star-group">
                                       
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                    </div>
                                    <div className="quotes">
                                        <ImQuotesRight />
                                    </div>
                                </div>
                                <p className='text-justify'>I am very happy to share my experience with Dhavanesha Interiors in transforming my home to an aesthetic haven.  Be it minor alterations or major renovation, the team has all the creativity, expertise and dedicated work culture that is inevitable in the process of making a family 's dream living space.  </p>
                            </div>
                        </div>
                        
                        <div class="col-md-4" data-aos="flip-left" data-aos-duration="3000">
                            <div class="contact-bx">
                                <img src="../Assets/testimony2.jpg" alt="Location" />
                                <h3 class="sect-title-3">Shena Samhita Rainbow MTB</h3>
                                <div className="star-quotes-group d-flex justify-content-between">
                                    <div className="star-group">
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                    </div>
                                    <div className="quotes">
                                        <ImQuotesRight />
                                    </div>
                                </div>
                                <p>I am delighted to share my experience with Dhavanesha Interiors, who recently transformed my flat into a stunning living space. From conceptualization to execution, their team demonstrated exceptional professionalism and creativity. Every detail was meticulously crafted, reflecting their commitment to quality and customer satisfaction. I commend Dhavanesha Interiors for their expertise in blending functionality with aesthetic appeal, creating a space that truly feels like home. Their dedication to understanding my preferences and translating them into reality exceeded my expectations. Thank you, Dhavanesha Interiors, for your outstanding work and for making this entire process seamless and enjoyable. I would highly recommend their services to anyone seeking top-notch interior design solutions.</p>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="contact-bx" data-aos="flip-left" data-aos-duration="3000">
                                <img src="../Assets/testimony3.jpg" alt="Location" />
                                <h3 class="sect-title-3">Senthil Vaswani </h3>
                                <div className="star-quotes-group d-flex justify-content-between">
                                    <div className="star-group">
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                        <FaStar className="str"/>
                                    </div>
                                    <div className="quotes">
                                        <ImQuotesRight />
                                    </div>
                                </div>
                                <p >Transforming our home with Dhavanesha Interiors demonstrated exceptional professionalism throughout the entire project. They listened attentively to my ideas and preferences, offering creative solutions that perfectly complemented my vision. From the selection of materials to the final execution, every step was carried out with precision and care. I am particularly impressed with their ability to balance aesthetics with practicality, creating a space that is both stylish and comfortable. Their commitment to quality and customer satisfaction is evident in the impeccable finish of their work. I highly recommend Dhavanesha Interiors to anyone looking for top-notch interior design services. Thank you for making my home a place I look forward to returning to every day.</p>
                            </div>
                        </div>
                    </div>
                </Container> */}

{/* <Container>
      <Carousel>
        <Carousel.Item>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
                <img src="../Assets/testimony1.jpg" alt="Location" />
                <h3 className="sect-title-3">Lakshmi Narayanan</h3>
                <div className="star-quotes-group d-flex justify-content-between">
                  <div className="star-group">
                    <FaStar className="str" />
                    <FaStar className="str" />
                    <FaStar className="str" />
                    <FaStar className="str" />
                    <FaStar className="str" />
                  </div>
                  <div className="quotes">
                    <ImQuotesRight />
                  </div>
                </div>
                <p className="text-justify">
                  I am very happy to share my experience with Dhavanesha Interiors in transforming my home to an aesthetic haven. Be it minor alterations or major renovation, the team has all the creativity, expertise and dedicated work culture that is inevitable in the process of making a family 's dream living space.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
                <img src="../Assets/testimony2.jpg" alt="Location" />
                <h3 className="sect-title-3">Shena Samhita Rainbow MTB</h3>
                <div className="star-quotes-group d-flex justify-content-between">
                  <div className="star-group">
                    <FaStar className="str" />
                    <FaStar className="str" />
                    <FaStar className="str" />
                    <FaStar className="str" />
                    <FaStar className="str" />
                  </div>
                  <div className="quotes">
                    <ImQuotesRight />
                  </div>
                </div>
                <p>
                  I am delighted to share my experience with Dhavanesha Interiors, who recently transformed my flat into a stunning living space. From conceptualization to execution, their team demonstrated exceptional professionalism and creativity. Every detail was meticulously crafted, reflecting their commitment to quality and customer satisfaction. I commend Dhavanesha Interiors for their expertise in blending functionality with aesthetic appeal, creating a space that truly feels like home. Their dedication to understanding my preferences and translating them into reality exceeded my expectations. Thank you, Dhavanesha Interiors, for your outstanding work and for making this entire process seamless and enjoyable. I would highly recommend their services to anyone seeking top-notch interior design solutions.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
                <img src="../Assets/testimony3.jpg" alt="Location" />
                <h3 className="sect-title-3">Senthil Vaswani</h3>
                <div className="star-quotes-group d-flex justify-content-between">
                  <div className="star-group">
                    <FaStar className="str" />
                    <FaStar className="str" />
                    <FaStar className="str" />
                    <FaStar className="str" />
                    <FaStar className="str" />
                  </div>
                  <div className="quotes">
                    <ImQuotesRight />
                  </div>
                </div>
                <p>
                  Transforming our home with Dhavanesha Interiors demonstrated exceptional professionalism throughout the entire project. They listened attentively to my ideas and preferences, offering creative solutions that perfectly complemented my vision. From the selection of materials to the final execution, every step was carried out with precision and care. I am particularly impressed with their ability to balance aesthetics with practicality, creating a space that is both stylish and comfortable. Their commitment to quality and customer satisfaction is evident in the impeccable finish of their work. I highly recommend Dhavanesha Interiors to anyone looking for top-notch interior design services. Thank you for making my home a place I look forward to returning to every day.
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </Container> */}

    {/* carousel */}

    {/* <Container>
      <Carousel className="main-content-box mb-5">
        <Carousel.Item>
          <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
            <img src="../Assets/testimony1.jpg" alt="Location" />
            <h3 className="sect-title-3">Lakshmi Narayanan</h3>
            <div className="star-quotes-group d-flex justify-content-between">
              <div className="star-group">
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
              </div>
              <div className="quotes">
                <ImQuotesRight />
              </div>
            </div>
            <p className="text-justify">
              I am very happy to share my experience with Dhavanesha Interiors in transforming my home to an aesthetic haven. Be it minor alterations or major renovation, the team has all the creativity, expertise and dedicated work culture that is inevitable in the process of making a family 's dream living space.
            </p>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
            <img src="../Assets/testimony2.jpg" alt="Location" />
            <h3 className="sect-title-3">Shena Samhita Rainbow MTB</h3>
            <div className="star-quotes-group d-flex justify-content-between">
              <div className="star-group">
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
              </div>
              <div className="quotes">
                <ImQuotesRight />
              </div>
            </div>
            <p>
              I am delighted to share my experience with Dhavanesha Interiors, who recently transformed my flat into a stunning living space. From conceptualization to execution, their team demonstrated exceptional professionalism and creativity. Every detail was meticulously crafted, reflecting their commitment to quality and customer satisfaction. I commend Dhavanesha Interiors for their expertise in blending functionality with aesthetic appeal, creating a space that truly feels like home. Their dedication to understanding my preferences and translating them into reality exceeded my expectations. Thank you, Dhavanesha Interiors, for your outstanding work and for making this entire process seamless and enjoyable. I would highly recommend their services to anyone seeking top-notch interior design solutions.
            </p>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
            <img src="../Assets/testimony3.jpg" alt="Location" />
            <h3 className="sect-title-3">Senthil Vaswani</h3>
            <div className="star-quotes-group d-flex justify-content-between">
              <div className="star-group">
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
              </div>
              <div className="quotes">
                <ImQuotesRight />
              </div>
            </div>
            <p>
              Transforming our home with Dhavanesha Interiors demonstrated exceptional professionalism throughout the entire project. They listened attentively to my ideas and preferences, offering creative solutions that perfectly complemented my vision. From the selection of materials to the final execution, every step was carried out with precision and care. I am particularly impressed with their ability to balance aesthetics with practicality, creating a space that is both stylish and comfortable. Their commitment to quality and customer satisfaction is evident in the impeccable finish of their work. I highly recommend Dhavanesha Interiors to anyone looking for top-notch interior design services. Thank you for making my home a place I look forward to returning to every day.
            </p>
          </div>
        </Carousel.Item>
      </Carousel>
    </Container> */}

    {/* owl carosel */}

    <Container>
        <div className="containt">
      <OwlCarousel
        className="owl-theme"
        loop
        margin={100}
        nav
        items={1}
        autoplay
        autoplayTimeout={10000}
      >
        <div className="item">
        <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
            <div>
          <img src="../Assets/testimony1.jpg" alt="Location" className="tst1" style={{width:"100px",height:"100px"}}/>
          </div>
          <h3 className="sect-title-3">Lakshmi Narayanan</h3>
          <h5>Ranka Heights Apartments, Domlur</h5>
          <div className="star-quotes-group d-flex justify-content-between">
            <div className="star-group">
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
            </div>
            <div className="quotes">
              <ImQuotesRight />
            </div>
          </div>
          <p className="text-justify">
            I am very happy to share my experience with Dhavanesha Interiors in transforming my home to an aesthetic haven. Be it minor alterations or major renovation, the team has all the creativity, expertise and dedicated work culture that is inevitable in the process of making a family's dream living space.
          </p>
        </div>
        </div>
        <div className="item">
        <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
          <img src="../Assets/testimony2.jpg" alt="Location" className="tst1" style={{width:"100px",height:"100px"}}/>
          <h3 className="sect-title-3">Shena Samhita</h3>
          <h5>Rainbow MTB</h5>
          <div className="star-quotes-group d-flex justify-content-between">
            <div className="star-group">
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
            </div>
            <div className="quotes">
              <ImQuotesRight />
            </div>
          </div>
          <p>
            I am delighted to share my experience with Dhavanesha Interiors, who recently transformed my flat into a stunning living space. From conceptualization to execution, their team demonstrated exceptional professionalism and creativity. Every detail was meticulously crafted, reflecting their commitment to quality and customer satisfaction. I commend Dhavanesha Interiors for their expertise in blending functionality with aesthetic appeal, creating a space that truly feels like home. Their dedication to understanding my preferences and translating them into reality exceeded my expectations. Thank you, Dhavanesha Interiors, for your outstanding work and for making this entire process seamless and enjoyable. I would highly recommend their services to anyone seeking top-notch interior design solutions.
          </p>
        </div>
</div>


<div className="item" >
        <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
          <img src="../Assets/testimony3.jpg" alt="Location" className="tst1" style={{width:"100px",height:"100px"}}/>
          <h3 className="sect-title-3">Senthil Vaswani</h3>
          <div className="star-quotes-group d-flex justify-content-between">
            <div className="star-group">
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
              <FaStar className="str" />
            </div>
            <div className="quotes">
              <ImQuotesRight />
            </div>
          </div>
          <p>
            Transforming our home with Dhavanesha Interiors demonstrated exceptional professionalism throughout the entire project. They listened attentively to my ideas and preferences, offering creative solutions that perfectly complemented my vision. From the selection of materials to the final execution, every step was carried out with precision and care. I am particularly impressed with their ability to balance aesthetics with practicality, creating a space that is both stylish and comfortable. Their commitment to quality and customer satisfaction is evident in the impeccable finish of their work. I highly recommend Dhavanesha Interiors to anyone looking for top-notch interior design services. Thank you for making my home a place I look forward to returning to every day.
          </p>
        </div>
        </div>
      </OwlCarousel>
      </div>
    </Container>


    {/* <Container>
      <Carousel className="main-content-box mb-5">
        <Carousel.Item>
          <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
            <img src="../Assets/testimony1.jpg" alt="Location" />
            <h3 className="sect-title-3">Lakshmi Narayanan</h3>
            <div className="star-quotes-group d-flex justify-content-between">
              <div className="star-group">
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
              </div>
              <div className="quotes">
                <ImQuotesRight />
              </div>
            </div>
            <p className="text-justify">
              I am very happy to share my experience with Dhavanesha Interiors in transforming my home to an aesthetic haven. Be it minor alterations or major renovation, the team has all the creativity, expertise and dedicated work culture that is inevitable in the process of making a family 's dream living space.
            </p>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
            <img src="../Assets/testimony2.jpg" alt="Location" />
            <h3 className="sect-title-3">Shena Samhita Rainbow MTB</h3>
            <div className="star-quotes-group d-flex justify-content-between">
              <div className="star-group">
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
              </div>
              <div className="quotes">
                <ImQuotesRight />
              </div>
            </div>
            <p>
              I am delighted to share my experience with Dhavanesha Interiors, who recently transformed my flat into a stunning living space. From conceptualization to execution, their team demonstrated exceptional professionalism and creativity. Every detail was meticulously crafted, reflecting their commitment to quality and customer satisfaction. I commend Dhavanesha Interiors for their expertise in blending functionality with aesthetic appeal, creating a space that truly feels like home. Their dedication to understanding my preferences and translating them into reality exceeded my expectations. Thank you, Dhavanesha Interiors, for your outstanding work and for making this entire process seamless and enjoyable. I would highly recommend their services to anyone seeking top-notch interior design solutions.
            </p>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="contact-bx" data-aos="flip-left" data-aos-duration="3000">
            <img src="../Assets/testimony3.jpg" alt="Location" />
            <h3 className="sect-title-3">Senthil Vaswani</h3>
            <div className="star-quotes-group d-flex justify-content-between">
              <div className="star-group">
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
                <FaStar className="str" />
              </div>
              <div className="quotes">
                <ImQuotesRight />
              </div>
            </div>
            <p>
              Transforming our home with Dhavanesha Interiors demonstrated exceptional professionalism throughout the entire project. They listened attentively to my ideas and preferences, offering creative solutions that perfectly complemented my vision. From the selection of materials to the final execution, every step was carried out with precision and care. I am particularly impressed with their ability to balance aesthetics with practicality, creating a space that is both stylish and comfortable. Their commitment to quality and customer satisfaction is evident in the impeccable finish of their work. I highly recommend Dhavanesha Interiors to anyone looking for top-notch interior design services. Thank you for making my home a place I look forward to returning to every day.
            </p>
          </div>
        </Carousel.Item>
      </Carousel>
    </Container> */}
    </section> 
                
   </div>

        

            <div className="customersayinner customer2">
                <img src="./Assets/customerfeedback.jpg" alt="" />
            </div>
        </div> 
    );
};

export default CustomerSays;
