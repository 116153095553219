import React from 'react'

const OurStory = () => {
    return (
        <div className='d-flex ourstory-main' data-aos="zoom-in" data-aos-duration="2000">
            <div className='col-md-4 card why-border m-2  '  >
                <img className='  ' src="/Assets/abt-img.jpg" alt="" />
            </div>

            <div className='col-md-8 d-flex justify-content-center flex-column  m-2 '>
                <p className='ourstory-para' style={{ textAlign: "justify", color: "gray" }}>
                    Founded in 1997, Dhavanesha Interiors has been a cornerstone in the interiors industry. We've distilled 28 years of industry wisdom into crafting your perfect home. From humble
            beginnings to becoming a leader in design and craftsmanship, our journey has been marked by a
                    dedication to quality and customer satisfaction. As a third-generation family business, we have
                    inherited a legacy of passion and expertise in transforming spaces.
                </p>
            </div>
        </div>
    )
}

export default OurStory