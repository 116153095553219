import React, { useEffect } from 'react'
import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import Footerf from '../Footer/Footerf';
import Header from '../Header';
import Aos from 'aos';

const ResidentialDesign = () => {
    useEffect(() => {
        Aos.init()
        window.scroll(0, 0)
    })

    let navigate = useNavigate()
    function Residential(params) {

        navigate("/service/Residential")

    }
    function Office(params) {

        navigate("/service/Office")

    }
    function Hospitality(params) {

        navigate("/service/Hospitality")

    }
    function service(params) {

        navigate("/service")

    }

    function navigatehome(params) {
        navigate("/")
    }

    return (
        <div className='main-servce-common-content-all'>
            <Header />
            <div className="headerServiceMain">
                <header className="servicenavbar">
                    <div className="bothside bothside1 ">
                        <p className="display-5 services-common-inner-content">Residential Design</p>
                    </div>
                    <div className="bothside bothside2">
                        <span onClick={navigatehome}>Home</span> / <span onClick={service}>Service</span> / <span><u>Residential Design</u></span>
                    </div>
                </header>
                <figure className="figuretagService">
                    <img src="/Assets/Servicenavbar.jpg" alt="" />
                </figure>
            </div>
            <Container className='d-flex the-main-container-content'>

                <div className='col-md-3  common-all-div-service-content ' >

                    <div className="stickycontroll1">
                        <ul className='list-unstyled  ul-main'>

                            <li className="listmain liststyle1 bgcolor  " onClick={Residential}>
                                Residential Design
                            </li>
                            <li className="listmain liststyle2 " onClick={Office}>
                                Office Design
                            </li>
                            <li className="listmain liststyle3" onClick={Hospitality}>
                                Hospitality Design
                            </li>
                        </ul>
                    </div>


                </div>
                <div className='col-md-5' >

                    <div className='centercommondiv p-2 ' >
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Art of Residential Design</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Designing a home is an art that combines functionality, aesthetics, and personal touch. We believe
                            that every home should tell a story (your story). Our goal is to create spaces that are as unique as
                            you are.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Personalized Layouts</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                            Every family has different needs. We design layouts that maximize space, enhance flow, and cater to
                            your specific lifestyle. From open-concept living areas to cozy private spaces, we create layouts that
                            work for you.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Stylish and Comfortable Furniture</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Furniture is the cornerstone of interior design. We help you choose pieces that are both stylish and
                            comfortable, ensuring that your home is a place where you can truly relax.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Lighting Ambiance</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Lighting sets the mood in your home. We use a mix of natural and artificial lighting to create the
                            perfect ambiance for every room. Whether it&#39;s soft lighting for a cozy bedroom or bright lights for a
                            lively kitchen, we’ve got it covered.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Color Palettes</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Colors have the power to transform a space. We select color schemes that complement your taste
                            and the overall design of your home. From calming neutrals to bold accents, we create a harmonious
                            color palette that enhances your living space.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Custom Storage Solutions</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Clutter-free spaces are key to a peaceful home. We design custom storage solutions that blend
                            seamlessly with your décor, ensuring that everything has its place.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Natural Elements</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Bringing the outdoors in adds a refreshing touch to any home. We incorporate natural elements like
                            plants, wood, and stone to create a warm and inviting atmosphere.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Tailored Design Services</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            We understand that every home is unique, and our design services are tailored to meet your
                            individual needs. Whether you’re looking to renovate a single room or design an entire home, we
                            provide personalized solutions that fit your style and budget.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Eco-Friendly Design</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Sustainability is important to us. We prioritize the use of eco-friendly materials and energy-efficient
                            solutions to create homes that are not only beautiful but also kind to the environment.
                        </p>

                        <p className='heading-service-common-content-center text-center' data-aos="fade-up" data-aos-duration="2000">Our Design Process</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Our process is collaborative and client-focused, ensuring that your vision is at the heart of every
                            decision:
                        </p>

                        <Row md={2} className='filper-main-content-main-div'>
                            <Col md={{ offset: 3 }} className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Consultation</p>
                                    </div>
                                    <div className=" fliper flip-box-back">
                                        <p className='p-1'>Understanding your needs, preferences, and budget.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Concept Development</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Creating initial design concepts and mood boards.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Design Refinement</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Refining the design based on your feedback.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Design Refinement</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Managing the project to ensure a seamless transformation.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Final Touches</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Adding the finishing details to perfect your space.</p>
                                    </div>
                                </div>

                            </Col>
                        </Row>


                    </div>

                </div>
                <div className='col-md-4 '  >

                    <div className="stickycontroll">
                        <div className='image-common-div-service-content-main' >

                            <div className='image-common-div-service-content'>
                                <img src="/Assets/servicetype1-1.jpg" alt="sad" />
                            </div>
                            <div className='image-common-div-service-content'>
                                <img src="/Assets/servicetype1-2.jpg" alt="sad" />
                            </div>



                        </div>

                    </div>


                </div>



            </Container>



            <Footerf />
        </div>
    )
}

export default ResidentialDesign