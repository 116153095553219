import React from 'react'
import "./Ourprojects.css"
import { useNavigate } from 'react-router-dom'

const OurProjects = () => {
    const navigate = useNavigate()

    function movetothepage(params) {

        navigate("/gallery")
    }
    
    return (
        <div className='ourprojects-main-div  ' data-aos="fade-zoom-in" data-aos-duration="2000">
            <button className='ourprojects-main-btn' onClick={movetothepage}>
                View Our All Projects
            </button>
        </div>
    )
}

export default OurProjects