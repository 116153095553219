import React from 'react';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import '../Styles/Header.css';

function Header() {
  return (
    <div
      className="headers"

      style={{
        position: "sticky",
        top: "0px",
        zIndex: "999",
         backgroundColor: "white",
        //backgroundColor:"white",
      }}
    >


      {["xl"].map((expand) => (

        <Navbar
          key={expand}
          expand={expand}
          style={{ padding: "0px", backgroundColor: "white" }}
        >

          <Container fluid style={{ padding: "5px 20px" }}>

            <div className="d-flex gap-2">
              <a href="/" className="tail-text">
                <img src="../Assets/dlogo.png" alt="dlogo" className="logo" />
              </a>
            </div>

            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
            />

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >

              <Offcanvas.Header closeButton />

              <Offcanvas.Body>

                <Nav
                  className="justify-content-end flex-grow-1 pe-3"
                  style={{ alignItems: "center" }}
                >


                  <Nav.Link href="/" className="tail-text">
                    <b>HOME</b>

                  </Nav.Link>

                  <Nav.Link href="/aboutus" className="tail-text">
                   <b> ABOUT US</b>
                  </Nav.Link>

                  <Nav.Link href="/service" className="tail-text">
                   <b>SERVICES</b> 
                  </Nav.Link>

                  <Nav.Link href="/gallery" className="tail-text">
                    <b>GALLERY</b>
                  </Nav.Link>

                  <Nav.Link href="/contact" className="tail-text">
                    <b>CONTACT US</b>
                  </Nav.Link>

                </Nav>

              </Offcanvas.Body>

            </Navbar.Offcanvas>

          </Container>

        </Navbar>

      ))}

    </div>
  );
}

export default Header;
