import React, { useEffect } from "react";
import "../Styles/Aboutus.css";
import Aos from "aos";
import { Container } from "react-bootstrap";
import OurStory from "./Aboutus/OurStory";
import WhatSetUs from "./Aboutus/WhatSetUs";
import AboutOurServices from "./Aboutus/AboutOurServices";
import { useNavigate } from "react-router-dom";

const Aboutus = () => {
  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });
  let Navi = useNavigate()
  function navigatehome(params) {
    Navi("/")
  }
  return (
    <div className="main-about-us-div">

      <div className="headerServiceMain">
        <header className="servicenavbar">
          <div className="bothside bothside1 ">
            <p className="display-5 m-0">About us </p>
          </div>
          <div className="bothside bothside2">
            <span onClick={navigatehome}>Home</span> / <span ><u>About us</u></span>
          </div>
        </header>
        <figure className="figuretagService">
          <img src="/Assets/gallery3.jpg" alt="" />
          {/* <img src="/Assets/aboutusnav.jpg" style={{ objectFit: "scale-down" }} alt="" /> */}
        </figure>
      </div>

      <Container className="mt-2 mb-2">
        <OurStory />
        <WhatSetUs />
      </Container>


      <div>


        {/* mission & vision */}

        <div>
          <Container>
            <div className="row mt-3 mb-3">
              <div className="col-md-6 mt-2 mb-3 d-flex flex-column justify-content-center">
                <h1 className="mission-heading p-0">Mission</h1>
                <p
                  className="mission-content "
                  data-aos="slide-up"
                  data-aos-duration="3000"
                  style={{ textIndent: "2em", textAlign: "justify" }}
                >
                  DHAVANESHA Interiors, our mission is to elevate everyday living by
                  crafting exceptional interior spaces that inspire, innovate,
                  and endure. We achieve this through a relentless pursuit of
                  design excellence, personalized client collaboration, and a
                  commitment to sustainable practices.
                </p>
              </div>

              <div className="col-md-6 mt-3 mb-2">
                <div>
                  <img
                    className="mission-image"
                    src="../Assets/commercial.jpg"
                    alt="mission "
                    data-aos="zoom-in"
                    data-aos-duration="3000"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4 mb-2">
              <div className="col-md-6">
                <div>
                  <img
                    className="vision-image"
                    src="../Assets/vision.jpg"
                    alt="vision"
                    data-aos="zoom-in"
                    data-aos-duration="3000"
                  />
                </div>
              </div>

              <div className="col-md-6 mt-2 mb-3 d-flex flex-column justify-content-center">
                <h1 className="mission-heading p-0">Vision</h1>
                <p
                  className="mission-content "
                  data-aos="slide-up"
                  data-aos-duration="3000"
                  style={{ textIndent: "2em", textAlign: "justify" }}

                >
                  Our vision at Dhavanesha Interiors is to redefine interior design by
                  consistently delivering transformative, tailored environments
                  that surpass expectations. We aspire to be recognized globally
                  for our creativity, craftsmanship, and the enduring quality of
                  our spaces, setting new benchmarks in the industry.
                </p>
              </div>
            </div>
          </Container>
        </div>

        {/* Why choose Dhavanesha */}

        <Container>
          <AboutOurServices />

        </Container>
        {/* <div className="container mt-4 mb-3 ">
          <h1 className="why-dhavanesha">Why Choose DHAVANESHA?</h1>

          <div
            className="row d-flex justify-content-evenly gap-4 p-2 mt-4"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <div className="col-md-5">
              <div className="card why-border">
                <img
                  src="/Assets/loyal-customer-1.png"
                  className="card-img-top why-icons"
                  alt="..."
                />
                <div className="card-body">
                  <h6 className="card-title color-heading">Experience</h6>
                  <p className="card-text paragraph">
                    Over two decades of expertise in interior design.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-5">
              <div className="card why-border">
                <img
                  src="/Assets/fast-delivery.png"
                  className="card-img-top why-icons"
                  alt="..."
                />
                <div className="card-body">
                  <h6 className="card-title color-heading">Quality</h6>
                  <p className="card-text paragraph">
                    Uncompromising standards in materials and craftsmanship.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row d-flex justify-content-evenly gap-4 p-2 mt-3"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <div className="col-md-5">
              <div className="card why-border">
                <img
                  src="/Assets/warrenty.png"
                  className="card-img-top why-icons"
                  alt="..."
                />
                <div className="card-body">
                  <h6 className="card-title color-heading">Innovation</h6>
                  <p className="card-text paragraph">
                    Cutting-edge designs that reflect contemporary trends while
                    honoring classic styles.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-5">
              <div className="card why-border">
                <img
                  src="/Assets/assure.png"
                  className="card-img-top why-icons"
                  alt="..."
                />
                <div className="card-body">
                  <h6 className="card-title color-heading">Legacy </h6>
                  <p className="card-text paragraph">
                    A third-generation family business with a deep-rooted
                    passion for design.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Our Team */}

        <div className="m-3" data-aos="fade-up" data-aos-duration="2000">
          <h1 className="ourteam-mainheading text-center">Our Commitment to Quality</h1>
          <Container>
            <div className="ourteam-content  ">
              <q className=" " style={{ color: "gray", textAlign: "justify" }}>

                Quality craftsmanship and attention to detail define everything we do at Dhavanesha Interiors. We source
                materials meticulously and collaborate with skilled artisans to ensure the highest standards of
                workmanship in every project.
              </q>
            </div>
          </Container>
        </div>

        {/* Happy Customers */}

        {/* <div className="container-fluid cont-back-color mt-4 p-3">
          <h1 className="happy mt-4">OUR HAPPY CUSTOMERS SO FAR</h1>

          <div className="circle-1">
            <div
              className="contents"
              data-aos="zoom-out"
              data-aos-duration="3000"
            >
              <h2 className="mt-4">115</h2>
              <p className="mt-2">
                Designed <br />
                Interior
              </p>
            </div>

            <div className="contents">
              <div
                className="text-center"
                data-aos="zoom-out"
                data-aos-duration="3000"
              >
                <h2 className="mt-4">365</h2>
                <p className="mt-2">
                  Home <br />
                  Interior
                </p>
              </div>
            </div>

            <div
              className="contents"
              data-aos="zoom-out"
              data-aos-duration="3000"
            >
              <h2 className="mt-4">424</h2>
              <p className="mt-2">
                Office
                <br />
                Interior
              </p>
            </div>

            <div
              className="contents"
              data-aos="zoom-out"
              data-aos-duration="3000"
            >
              <h2 className="mt-4">275</h2>
              <p className="mt-2">
                Award <br />
                Winnigs
              </p>
            </div>
          </div>
        </div> */}


      </div >

    </div >
  );
};

export default Aboutus;
