import React, { useEffect } from 'react'
import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import Footerf from '../Footer/Footerf';
import Header from '../Header';
import Aos from 'aos';

const HospitalDesign = () => {

    let navigate = useNavigate()
    function Residential(params) {

        navigate("/service/Residential")

    }
    function Office(params) {

        navigate("/service/Office")

    }
    function Hospitality(params) {

        navigate("/service/Hospitality")

    }
    function service(params) {

        navigate("/service")

    }
    function navigatehome(params) {
        navigate("/")
    }
    useEffect(() => {
        Aos.init()
        window.scroll(0, 0)
    })
    return (
        <div className='main-servce-common-content-all'>
            <Header />
            <div className="headerServiceMain">
                <header className="servicenavbar">
                    <div className="bothside bothside1 ">
                        <p className="display-5 services-common-inner-content">Hospitality Design</p>
                    </div>
                    <div className="bothside bothside2">
                        <span onClick={navigatehome}>Home</span> / <span onClick={service}>Service</span> / <span><u>Hospitality Design</u></span>
                    </div>
                </header>
                <figure className="figuretagService">
                    <img src="/Assets/Servicenavbar.jpg" alt="" />
                </figure>
            </div>
            <Container className='d-flex the-main-container-content'>

                <div className='col-md-3  common-all-div-service-content ' >

                    <div className="stickycontroll1">
                        <ul className='list-unstyled  ul-main'>

                            <li className="listmain liststyle1" onClick={Residential}>
                                Residential Design
                            </li>
                            <li className="listmain liststyle2 " onClick={Office}>
                                Office Design
                            </li>
                            <li className="listmain liststyle3 bgcolor " onClick={Hospitality}>
                                Hospitality Design
                            </li>
                        </ul>
                    </div>


                </div>
                <div className='col-md-5' >

                    <div className='centercommondiv p-2'>
                        <div className='mb-1'>
                            <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">The Essence of Hospitality Design</p>
                            <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                                In the hospitality industry, first impressions are everything. Our designs blend functionality with
                                elegance, ensuring that your space is not only beautiful but also practical. We focus on creating
                                environments that leave a lasting impression on your guests.
                            </p>
                            <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Welcoming Atmosphere</p>
                            <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                                A warm and welcoming atmosphere is essential in hospitality. From the moment guests walk
                                through the door, they should feel comfortable and at home. We achieve this through thoughtful
                                layout, lighting, and décor choices.
                            </p>
                            <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Elegant and Functional Furniture</p>
                            <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                                Furniture in hospitality settings must be both elegant and durable. We select pieces that enhance
                                the aesthetic appeal of your space while withstanding the wear and tear of daily use.
                            </p>
                            <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Ambient Lighting</p>
                            <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                                Lighting plays a crucial role in setting the mood. We use a combination of natural light, ambient
                                lighting, and accent lights to create a cozy and inviting atmosphere that complements your brand.
                            </p>
                            <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Color and Texture</p>
                            <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                                Colors and textures can evoke specific emotions and enhance the overall guest experience. We
                                choose color palettes and materials that align with your brand and create a cohesive and appealing
                                look.
                            </p>
                            <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Innovative Technology</p>
                            <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                                Incorporating the latest technology is vital in modern hospitality design. We ensure your space is
                                equipped with smart solutions for lighting, climate control, and guest services, providing a seamless
                                and comfortable experience.
                            </p>
                            <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Personalized Spaces</p>
                            <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                                Personalized touches make guests feel special. We design spaces that can be tailored to individual
                                needs, whether it’s a cozy corner in a restaurant or a luxurious suite in a hotel.
                            </p>
                            <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Tailored Design Solutions</p>
                            <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                                We understand that each hospitality venue has its unique challenges and opportunities. Our tailored
                                design solutions ensure that your space stands out and meets the specific needs of your guests,
                                whether you’re running a boutique hotel, a fine dining restaurant, or a bustling café.
                            </p>
                            <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Sustainable Hospitality Design</p>
                            <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                                Sustainability is at the heart of our design philosophy. We prioritize eco-friendly materials, energy-
                                efficient systems, and sustainable practices to create spaces that are not only beautiful but also
                                environmentally responsible.
                            </p>

                            <p className='heading-service-common-content-center text-center' data-aos="fade-up" data-aos-duration="2000">Our Design Process</p>
                            <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                                Our design process is collaborative and client-centric, ensuring that your vision is brought to life with
                                precision and creativity:
                            </p>
                        </div>

                        <Row md={2} className='filper-main-content-main-div'>
                            <Col md={{ offset: 3 }} className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Consultation</p>
                                    </div>
                                    <div className=" fliper flip-box-back">
                                        <p className='p-1'>Understanding your needs, preferences, and budget.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Concept Development</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Creating initial design concepts and mood boards.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Design Refinement</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Refining the design based on your feedback.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Implementation:</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Overseeing the project to ensure smooth execution.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Final Touches</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Adding the finishing touches to ensure every detail is perfect.</p>
                                    </div>
                                </div>

                            </Col>
                        </Row>


                    </div>

                </div>
                <div className='col-md-4 '  >

                    <div className="stickycontroll">
                        <div className='image-common-div-service-content-main' >

                            <div className='image-common-div-service-content'>
                                <img src="/Assets/servicetype3-1.jpg" alt="" />
                            </div>
                            <div className='image-common-div-service-content'>
                                <img src="/Assets/servicetype3-2.jpg" alt="" />
                            </div>



                        </div>

                    </div>


                </div>



            </Container>

            <Footerf />


        </div>
    )
}

export default HospitalDesign