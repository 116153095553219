import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GiStarMedal } from "react-icons/gi";
import { LiaSlideshare } from "react-icons/lia";
import { PiPersonArmsSpreadFill } from "react-icons/pi";

const WhatSetUs = () => {
    return (
        <div>
            <div className="container-header-we-do">
                <div className="display-6 pt-3 text-center whatsetus"> What Set Us Apart   </div>
                <div class="separator  ">
                    <span>
                        <i class="fa fa-circle">*--*</i>
                    </span>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <Container>
                    <Row md={3} className='aboutus-setusapart-content '>
                        
                        <Col className='d-flex justify-content-center align-items-center' data-aos="fade-up" data-aos-duration="2000">
                            <div class="flip-box2">
                                <div class="flip-box-inner2">
                                    <div class="flip-box-front2">
                                        <div className='img-div-icon-whatset'>
                                            <GiStarMedal className='icon-aboutus' />
                                        </div>
                                        <h3>Legacy of Excellence</h3>
                                    </div>
                                    <div class="flip-box-back2">
                                        <p className='m-0' >With over two decades of experience, we have honed our skills in
                                            creating bespoke interiors that merge functionality with aesthetic appeal.</p>

                                        <pre>
                                            &nbsp;
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </Col>


                        <Col className='d-flex justify-content-center align-items-center' data-aos="fade-down" data-aos-duration="2000">
                            <div class="flip-box2">
                                <div class="flip-box-inner2">
                                    <div class="flip-box-front2">
                                        <div className='img-div-icon-whatset'>
                                            <LiaSlideshare className='icon-aboutus' />
                                        </div>
                                        <h3>Innovation and Creativity</h3>
                                    </div>
                                    <div class="flip-box-back2">
                                        <p className='m-0'>
                                            We embrace innovation in design and technology, ensuring our
                                            solutions are at the forefront of contemporary trends.

                                        </p>
                                        <pre>
                                            &nbsp;
                                        </pre>


                                    </div>
                                </div>
                            </div>
                        </Col>


                        <Col className='d-flex justify-content-center align-items-center' data-aos="fade-up" data-aos-duration="2000">
                            <div class="flip-box2">
                                <div class="flip-box-inner2">
                                    <div class="flip-box-front2">
                                        <div className='img-div-icon-whatset'>
                                            <PiPersonArmsSpreadFill className='icon-aboutus' />
                                        </div>

                                        <h3>Customer-Centric Approach </h3>
                                    </div>
                                    <div class="flip-box-back2">
                                        <p className='m-0' >Your vision is our priority. We collaborate closely with clients
                                            to understand their unique needs and aspirations, delivering personalized designs that
                                            exceed expectations.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        
                    </Row>
                    <hr />

                </Container>

            </div>

        </div>
    )
}

export default WhatSetUs