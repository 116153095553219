import React from 'react';
import './Contactus.css';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';
import { Container } from 'react-bootstrap';

function ContactUs() {
    return (
        <div className="App1 ">
            <Container >
                <div className='contact-container'>
                    <ContactInfo />
                    <ContactForm />
                </div>
            </Container>
        </div>
    );
}

export default ContactUs;
