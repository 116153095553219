import React from 'react'
import { Container } from "react-bootstrap";
import "./OurProcess.css"


const OurProcess = () => {
    return (

        <div className='main-div-ourprocess overflow-hidden'>
            <div className='img-background-front'>
                <Container>
                    <div className="container-header-we-do">
                        <div className="display-6 pt-3 text-center">Our Process</div>
                        <div class="separator  ">
                            <span>
                                <i class="fa fa-circle">*--*</i>
                            </span>
                        </div>
                    </div>

                    <div>


                        <div className="main1div">

                            <div className="circle circle1" data-aos="zoom-in" data-aos-duration="2000">
                                Meet & Agree
                            </div>
                            <div className="circle circle2" data-aos="zoom-out" data-aos-duration="2000">
                                Idea & Concept
                            </div>
                            <div className="circle circle3" data-aos="zoom-in" data-aos-duration="2000">
                                Design & Create
                            </div>
                            <div className="circle circle4" data-aos="zoom-out" data-aos-duration="2000">
                                Build & Install

                            </div>
                        </div>

                        {/* <hr className='margin-hr-ourprocess' /> */}
                        <div className="main2div" data-aos="fade-zoom-out" data-aos-duration="2000" >

                            {/* <p className='text-white m-1 text-justify' style={{ textIndent: '2em' }}>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga adipisci,
                                laborum assumenda blanditiis minus non quibusdam aut modi ut possimus inventore praesentium nemo, nisi maiores amet voluptas ipsam. Non, dolor.




                            </p> */}
                        </div>




                    </div>

                </Container>
            </div>
            <div className='img-background'>
                <img src="../Assets/backgroundimgmain.jpg" alt="" />
            </div>
        </div>
    )
}

export default OurProcess