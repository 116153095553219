import React from "react";
import "./ServiceMain.css";
import { Container, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import ChatWidget from "../ChatWidget";

const ServicepageMain = () => {
    let Navi = useNavigate()
    function navigatehome(params) {
        Navi("/")
    }

    let navigate = useNavigate()
    function Residential(params) {

        navigate("/service/Residential")

    }
    function Office(params) {

        navigate("/service/Office")

    }
    function Hospitality(params) {

        navigate("/service/Hospitality")

    }
    return (
        <div className="mainServicepag">


            <div className="headerServiceMain">
                <header className="servicenavbar">
                    <div className="bothside bothside1 ">
                        <p className="display-5">Our Services</p>
                    </div>
                    <div className="bothside bothside2">
                        <span onClick={navigatehome}>Home</span> / <span><u>Service</u></span>
                    </div>
                </header>
                <figure className="figuretagService">
                    <img src="/Assets/Servicenavbar.jpg" alt="" />
                </figure>
            </div>

            <Container>
                <main className="serviceMainContent d-flex">
                    <Card className="card-service-controll" data-aos="fade-down" data-aos-duration="2000" >
                        <Card.Body>
                            <Card.Title style={{ fontWeight: 600 }}>Residential Design</Card.Title>
                            <Card.Text>
                                Designing a home is an art that combines functionality,
                                aesthetics, and personal touch. We believe that every home
                                should tell a story (your story). Our goal is to create spaces
                                that are as unique as you are.
                                <pre className="m-0">
                                    &nbsp;
                                </pre>
                            </Card.Text>
                        </Card.Body>
                        <Card.Img variant="false" className="Service-img-main-card-controll" src="./Assets/servicemain1.jpg" />
                        <Card.Body className="text-center">
                            {/* <Button onClick={Residential} className="knowmore">Know More</Button> */}
                            <button onClick={Residential} className="knowmore">Know More</button>
                        </Card.Body>
                    </Card>


                    <Card className="card-service-controll" data-aos="fade-up" data-aos-duration="2000" >
                        <Card.Body>
                            <Card.Title style={{ fontWeight: 600 }} >Office Design</Card.Title>
                            <Card.Text>
                                A well-designed office space is more than just aesthetically pleasing. It impacts employee
                                productivity, creativity, and overall well-being. Thoughtful office design can enhance collaboration,
                                reduce stress, and reflect your company’s culture and values.
                            </Card.Text>
                        </Card.Body>
                        <Card.Img variant="false" className="Service-img-main-card-controll" src="./Assets/servicemain4.jpg" />
                        <Card.Body className="text-center">
                            {/* <Button onClick={Office} className="knowmore">Know More</Button> */}
                            <button onClick={Office} className="knowmore">Know More</button>
                        </Card.Body>
                    </Card>


                    <Card className="card-service-controll" data-aos="fade-down" data-aos-duration="2000">
                        <Card.Body>
                            <Card.Title style={{ fontWeight: 600 }}>Hospitality Design</Card.Title>
                            <Card.Text>
                                In the hospitality industry, first impressions are everything. Our designs blend functionality with
                                elegance, ensuring that your space is not only beautiful but also practical. We focus on creating
                                environments that leave a lasting impression on your guests.
                            </Card.Text>
                        </Card.Body>
                        <Card.Img variant="false" className="Service-img-main-card-controll" src="./Assets/cardmain3.jpg" />
                        <Card.Body className="text-center">
                            {/* <Button onClick={Hospitality} className="knowmore">Know More</Button> */}
                            <button onClick={Hospitality} className="knowmore">Know More</button>
                        </Card.Body>
                    </Card>

                </main>

            </Container>
        </div>
    );
};

export default ServicepageMain;
