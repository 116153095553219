import "./App.css";

import Home from "./Components/Home";
import Gallery from "./Components/Gallery";
import Header from "./Components/Header";
import ChatWidget from "./Components/ChatWidget";
import Aboutus from "./Components/Aboutus";

import Services from "./Components/Services";
// import Footer from "./Components/Footer";
import Contact_us from "./Components/Contact_us";
import Footerf from "./Components/Footer/Footerf";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import ResidentialDesign from "./Components/ServiceComponents/ResidentialDesign";
import OfficeDesign from "./Components/ServiceComponents/OfficeDesign";
import HospitalDesign from "./Components/ServiceComponents/HospitalDesign";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Home />
                {/* <Footer/> */}

                <Footerf />
                <ChatWidget />
              </>
            }
          ></Route>

          <Route
            path="/aboutus"
            element={
              <>
                <Header />
                <Aboutus />
                <Footerf />

                {/* <Footer /> */}
                <ChatWidget />
              </>
            }
          ></Route>

          <Route
            path="/service"
            element={
              <>
                <Header />
                <Services />
                <Footerf />

                {/* <Footer /> */}
                <ChatWidget />
              </>
            }
          ></Route>

          <Route
            path="/gallery"
            element={
              <>
                <Header />
                <Gallery />
                <Footerf />

                {/* <Footer /> */}
                <ChatWidget />
              </>
            }
          ></Route>

          <Route
            path="/contact"
            element={
              <>
                <Header />
                <Contact_us />
                <Footerf />

                {/* <Footer /> */}
                <ChatWidget />
              </>
            }
          ></Route>

          <Route
            path="/service/Residential"
            element={<ResidentialDesign />}
          ></Route>
          <Route path="/service/Office" element={<OfficeDesign />}></Route>
          <Route
            path="/service/Hospitality"
            element={<HospitalDesign />}
          ></Route>
        </Routes>
      </BrowserRouter>

      {/* <ChatWidget/> */}
    </div>
  );
}

export default App;
