import React from 'react'
import './Footer.css'
import { Container } from 'react-bootstrap'

import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { Link } from 'react-router-dom';



const Footerf = () => {
    return (
        <div className='footer'>
            <Container>
                <div className='footer_container'>
                    <div className='footer_about col-md-5'>
                        <div>
                            <h4>About Dhavanesha Interiors</h4>
                            <p style={{ textAlign: "justify", textIndent: "2em" }}>Dhavanesha Interiors, where we bring your interior design dreams to life. With a rich history
                                dating back to 1997 and now in our third generation, our commitment to excellence and innovation
                                in the world of interiors remains unparalleled.</p>
                        </div>
                        <div>
                            <ol className='footer_ol'>
                                <li><a href="https://www.facebook.com/61561866178583/"><FaFacebookSquare className='social_icons' id='facebook' /></a></li>
                                <li >
                                    <a href="https://www.linkedin.com/in/dhavanesha-interiors-662a49315"><FaLinkedin className='social_icons' id='twitter' /></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/dhavanesha_interiors/"><FaInstagram className='social_icons' id='insta' /></a>
                                </li>
                                <li>
                                    <a href="https://maps.app.goo.gl/kDaxptaLeVc3k9DHA"><FaMapLocationDot className='social_icons' id='youtube' /></a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className='footer_quick col-md-2'>
                        <div>
                            <h3>Quick Links</h3>
                        </div>
                        <div>
                            <ol className='footer_quick_ol'>
                                <li className='quick_listss'><Link className='Link_remove_footer' to='/' >Home</Link></li>
                                <li className='quick_listss'><Link className='Link_remove_footer' to='/aboutus' >About Us</Link></li>
                                <li className='quick_listss'><Link className='Link_remove_footer' to='/service' >Services</Link></li>
                                <li className='quick_listss'><Link className='Link_remove_footer' to='/gallery' >Gallery</Link></li>
                                <li className='quick_listss'><Link className='Link_remove_footer' to='/contact' >Contact Us</Link></li>
                            </ol>
                        </div>
                    </div>

                    <div className='footer_contact col-md-5'>
                        <h3>Contact Details</h3>
                        <div className="info-footer  text-capitalize ">
                            <h4 className='m-0'>Home - Office</h4>

                            <p className='m-0'>Dhavanesha Interiors<br></br>
                               #30, N S Nilayam, First Floor,</p>
                            <p className='m-0' >2nd Main, Indira Gandhi Street</p>                               
                            <p className='m-0'>Bangalore – 560016, Karnataka</p>


                        </div>

                        <br></br>

                        <div className="info-footer  ">
                            <h4 className='m-0'>Factory</h4>

                            <p className='m-0'>Dhavanesha Interiors<br></br>
                                #22, Hoysala Nagar, Horamavu,</p>
                            <p className='m-0'>Ramamurthy Nagar, Bangalore – 560016,</p>
                            <p className='m-0'> Karnataka, India</p>
                        </div>

                        <p className='m-0' >
                            dhavaneshainteriors@gmail.com
                        </p>
                        <p className='m-0' >
                            +91 93803 99336
                        </p>

                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Footerf
