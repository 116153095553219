import React from 'react';
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

import { FaHome } from "react-icons/fa";

import { MdFactory } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";


const ContactInfo = () => {
    return (
        <div className="contact-info col-md-5 " data-aos="zoom-in" data-aos-duration="2000" >
            <div className="contact-item">
                <div className="icon">
                    <FaHome className="fas fa-home"></FaHome>
                </div>
                <div className="info  ">
                    <h4>Home - Office</h4>

                    <p>Dhavanesha Interiors</p>
                    <p>#30, N S Nilayam, First Floor, 2nd Main, Indira Gandhi Street,</p>

                    <p>Bangalore – 560016, Karnataka, India</p>

                </div>
            </div>
            <div className="contact-item">
                <div className="icon">
                    <MdFactory className="fas fa-home"></MdFactory>
                </div>
                <div className="info ">
                    <h4>Factory</h4>

                    <p className='text-justify'>Dhavanesha Interiors</p>
                     <p>   #22, Hoysala Nagar, Horamavu, Ramamurthy Nagar,

                        Bangalore – 560016, Karnataka, India
                    </p>
                </div>
            </div>
            <div className="contact-item">
                <div className="icon">
                    <FaPhoneVolume className="fas fa-phone-alt"></FaPhoneVolume>
                </div>
                <div className="info">
                    <h4>Phone</h4>
                    <p>+91 93803 99336</p>
                </div>
            </div>
            <div className="contact-item">
                <div className="icon">
                    <MdEmail className="fas fa-envelope"></MdEmail>
                </div>
                <div className="info">
                    <h4>Email</h4>
                    <p>dhavaneshainteriors@gmail.com</p>
                </div>
            </div>

            <div className='d-flex mt-2 mb-3 justify-content-evenly'>
                <div className="icon">
                    <a className='social-media-links' href="https://www.facebook.com/61561866178583/" > <FaFacebookSquare id='facebook' className="fas fa-home "></FaFacebookSquare></a>
                </div>
                <div className="icon">
                    <a className='social-media-links' href="https://www.instagram.com/dhavanesha_interiors/"> <FaInstagram id='insta' className="fas fa-home"></FaInstagram></a>
                </div>
                <div className="icon">
                    <a className='social-media-links' href="https://www.linkedin.com/in/dhavanesha-interiors-662a49315"> <FaLinkedin id='twitter' className="fas fa-home"></FaLinkedin></a>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
