const GData = [
    {
        type: "OFFICE",
        image: "/Assets/office1.jpg",
        details: "Dhavanesha Interior"

    },
    {
        type: "OFFICE",
        image: "/Assets/office5.webp",
        details: "Dhavanesha Interior"

    },
    
    {
        type: "OFFICE",
        image: "/Assets/office6.jpg",
        details: "Dhavanesha Interior"

    },
    
    
    {
        type: "OFFICE",
        image: "/Assets/office4.png",
        details: "Dhavanesha Interior"

    },



    // {
    //     type: "RESIDENTIAL",
    //     image: "/Assets/gallery4.jpg",
    //     details: "Dhavanesha Interior"

    // },
    // {
    //     type: "RESIDENTIAL",
    //     image: "/Assets/resident7.jpg",
    //     details: "Dhavanesha Interior"

    // },
    // {
    //     type: "RESIDENTIAL",
    //     image: "/Assets/cardmain1.jpg",
    //     details: "Dhavanesha Interior"

    // },
    
    // {
    //     type: "RESIDENTIAL",
    //     image: "/Assets/residential6.jpg",
    //     details: "Dhavanesha Interior"

    // },



    {
        type: "RESIDENTIAL",
        image: "/Assets/resid1.jpg",
        details: "Dhavanesha Interior"

    },
    {
        type: "RESIDENTIAL",
        image: "/Assets/resid5.jpg",
        details: "Dhavanesha Interior"

    },
    {
        type: "RESIDENTIAL",
        image: "/Assets/resid2.jpg",
        details: "Dhavanesha Interior"

    },
    
    {
        type: "RESIDENTIAL",
        image: "/Assets/resid3.jpg",
        details: "Dhavanesha Interior"

    },





    {
        type: "HOSPITALY",
        image: "/Assets/gallery7.jpg",
        details: "Dhavanesha Interior"

    },
    {
        type: "HOSPITALY",
        image: "/Assets/hotel2.jpg",
        details: "Dhavanesha Interior"

    },
    {
        type: "HOSPITALY",
        image: "/Assets/gd-h3.jpg",
        details: "Dhavanesha Interior"

    },
    {
        type: "HOSPITALY",
        image: "/Assets/hotel6.jpg",
        details: "Dhavanesha Interior"

    }
]

export default GData