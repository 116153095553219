import React, { useEffect } from 'react'
import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import Footerf from '../Footer/Footerf';
import Header from '../Header';
import Aos from 'aos';


const OfficeDesign = () => {

    let navigate = useNavigate()
    function Residential(params) {

        navigate("/service/Residential")

    }
    function Office(params) {

        navigate("/service/Office")

    }
    function Hospitality(params) {

        navigate("/service/Hospitality")

    }
    function service(params) {

        navigate("/service")

    }
    function navigatehome(params) {
        navigate("/")
    }
    useEffect(() => {
        Aos.init()
        window.scroll(0, 0)
    })
    return (
        <div className='main-servce-common-content-all'>
            <Header />
            <div className="headerServiceMain">
                <header className="servicenavbar">
                    <div className="bothside bothside1 ">
                        <p className="display-5">Office Design</p>
                    </div>
                    <div className="bothside bothside2">
                        <span onClick={navigatehome}>Home</span> / <span onClick={service}>Service</span> / <span><u>Office Design</u></span>
                    </div>
                </header>
                <figure className="figuretagService">
                    <img src="/Assets/Servicenavbar.jpg" alt="" />
                </figure>
            </div>
            <Container className='d-flex the-main-container-content'>

                <div className='col-md-3  common-all-div-service-content ' >

                    <div className="stickycontroll1">
                        <ul className='list-unstyled  ul-main'>

                            <li className="listmain liststyle1" onClick={Residential}>
                                Residential Design
                            </li>
                            <li className="listmain liststyle2  bgcolor " onClick={Office}>
                                Office Design
                            </li>
                            <li className="listmain liststyle3" onClick={Hospitality}>
                                Hospitality Design
                            </li>
                        </ul>
                    </div>


                </div>
                <div className='col-md-5' >

                    <div className='centercommondiv p-2'>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">The Importance of Thoughtful Office Design</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                            A well-designed office space is more than just aesthetically pleasing. It impacts employee
                            productivity, creativity, and overall well-being. Thoughtful office design can enhance collaboration,
                            reduce stress, and reflect your company’s culture and values.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Ergonomic Furniture</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000" >
                            Investing in ergonomic furniture is crucial. Comfortable chairs, adjustable desks, and supportive
                            accessories can reduce the risk of musculoskeletal problems and increase productivity.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Lighting</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Natural light is a game-changer in office design. It improves mood, energy levels, and focus. Where
                            natural light is limited, incorporate high-quality artificial lighting that mimics daylight.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Open vs. Private Spaces</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Balancing open collaborative areas with private spaces is essential. Open spaces encourage
                            teamwork and creativity, while private areas allow for focused, individual work and confidential
                            meetings.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Color Schemes</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Colors have a psychological impact. Cool colors like blue and green can create a calm and productive
                            environment, while warmer tones like red and yellow can stimulate creativity and energy.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Technology Integration</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Seamless integration of technology is vital in modern office design. Ensure your space is equipped
                            with the latest tech tools to support virtual meetings, collaborative work, and connectivity.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Biophilic Design</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Incorporating elements of nature, such as plants and natural materials, can reduce stress, increase
                            creativity, and improve air quality.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Custom Solutions for Every Space</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Every office is unique, and we offer customized solutions to meet your specific needs. Whether
                            you’re a startup in need of a dynamic and flexible space or a corporate office looking for a sleek and
                            professional environment, we have you covered.
                        </p>
                        <p className='heading-service-common-content-center' data-aos="fade-up" data-aos-duration="2000">Sustainable Office Design</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Sustainability is at the core of our design philosophy. We prioritize eco-friendly materials, energy-
                            efficient lighting, and sustainable practices to create offices that are not only beautiful but also kind
                            to the planet.
                        </p>

                        <p className='heading-service-common-content-center text-center' data-aos="fade-up" data-aos-duration="2000">Our Design Process</p>
                        <p className='paragraph-service-common-content' data-aos="fade-up" data-aos-duration="2000">
                            Our design process is collaborative and client-focused:
                        </p>

                        <Row md={2} className='filper-main-content-main-div'>
                            <Col md={{ offset: 3 }} className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Consultation</p>
                                    </div>
                                    <div className=" fliper flip-box-back">
                                        <p className=''>Understanding your needs, preferences, and budget.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Concept Development</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Creating initial design concepts and layouts.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Design Refinement</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Fine-tuning the design based on feedback.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Implementation</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Overseeing the transformation of your space.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Final Touches</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1 ' >Adding the finishing touches to ensure every detail is perfect.</p>
                                    </div>
                                </div>

                            </Col>
                        </Row>


                    </div>

                </div>
                <div className='col-md-4 '  >

                    <div className="stickycontroll">
                        <div className='image-common-div-service-content-main' >

                            <div className='image-common-div-service-content'>
                                <img src="/Assets/servicetype2-1.jpg" alt="" />
                            </div>
                            <div className='image-common-div-service-content'>
                                <img src="/Assets/servicetype2-2.jpg" alt="" />
                            </div>



                        </div>

                    </div>


                </div>



            </Container>


            <Footerf />

        </div>
    )
}

export default OfficeDesign