import React, { useEffect } from 'react'
import '../Styles/Services.css'
import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import { Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { FaPhoenixFramework } from "react-icons/fa";
// import { MdHighQuality } from "react-icons/md";
// import { FaEdgeLegacy } from "react-icons/fa6";
// import { GiWideArrowDunk } from "react-icons/gi";
// import { MdOutlineHighQuality } from "react-icons/md";

import { ImQuotesLeft } from "react-icons/im";



import Aos from 'aos'
import OurServices from './ServiceComponents/OurServices';
import ServicepageMain from './ServiceComponents/ServicepageMain';
import ResidentialDesign from './ServiceComponents/ResidentialDesign';


const Services = () => {

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,

      }
    },
  };

  useEffect(() => {
    Aos.init()
    window.scroll(0, 0)
  })


  return (

    <div>

      <ServicepageMain />
      {/* <ResidentialDesign /> */}


      {/* <Container >
        <OurServices />
      </Container> */}

      {/* <div >
        <img src="/Assets/ser-mainimg.avif" alt="service img" className='Service-img' />
        <div >
          <h1 className='service-text'>Services</h1>
        </div>
      </div> */}

      {/* <h1 style={{ color: "green" }}>Services</h1> */}

      {/* <Container>

        <div className='row mt-5 mb-4' id='img-over' data-aos="zoom-in" data-aos-duration="3000">

          <div className='col-md-6 mb-3'>

            <Card style={{ width: '100%' }}>
              <Card.Img variant="top" src="/Assets/resident.jpg" className='images' />
              <Card.Body>
                <Card.Title>Residential Interiors</Card.Title>
                <Card.Text>
                  Creating warm, inviting homes tailored to your style.
                </Card.Text>
              </Card.Body>
            </Card>

          </div>

          <div className='col-md-6 mb-2'>

            <Card style={{ width: '100%' }}>
              <Card.Img variant="top" src="/Assets/commercial.jpg" className='images' />
              <Card.Body>
                <Card.Title>Commercial Interiors</Card.Title>
                <Card.Text>
                  Designing efficient and inspiring workspaces.
                </Card.Text>
              </Card.Body>
            </Card>

          </div>
        </div>



        <div className='row mt-2 mb-4 justify-content-center' id='img-over' data-aos="zoom-in" data-aos-duration="3000">

          <div className='col-md-6 mb-2 d-flex justify-content-center'>

            <Card style={{ width: '100%' }}>
              <Card.Img variant="top" src="/Assets/img3.jpg" className='images' />
              <Card.Body>
                <Card.Title>Custom Furniture</Card.Title>
                <Card.Text>
                  Handcrafted pieces that blend aesthetics and utility.
                </Card.Text>
              </Card.Body>
            </Card>

          </div>

        </div>

      </Container>
 */}




      {/* Testimonial Cards */}

      {/* <div>

        <div>
          <h1 className='title-mainheading'>Testimonials</h1>
          <Container>
            <div className='testimonial-content'>Our team of seasoned designers, craftsmen, and project managers work collaboratively to ensure your vision becomes a reality. We pride ourselves on our attention to detail and personalized approach, making each project unique and tailored to our client's desires.</div>
          </Container>
        </div>


        <Container>
          <div className='row mt-3 mb-5' data-aos="zoom-in" data-aos-duration="3000">

            <div className='col-md-12'>
           
              <OwlCarousel className='owl-theme' loop margin={8} {...options}>

                <div class='item'>
                  <Card className='testimonial-crd'>
                    <Card.Header><ImQuotesLeft style={{ fontSize: 20 }} /></Card.Header>
                    <Card.Body>
                      <div>
                        <div className='content-card'>
                          We are experts in adding a finishing touch to your space through furnishings and decorative elements to achieve  the required aesthetics, ambience, and aura within your space.
                        </div>
                        <div className='name'>Nancy</div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>



                <div class='item'>
                  <Card className='testimonial-crd' >
                    <Card.Header><ImQuotesLeft style={{ fontSize: 20 }} /></Card.Header>
                    <Card.Body>
                      <div>
                        <div className='content-card'>
                          We are experts in adding a finishing touch to your space through furnishings and decorative elements to achieve  the required aesthetics, ambience, and aura within your space.
                        </div>
                        <div className='name'>John</div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>


                <div class='item'>
                  <Card className='testimonial-crd'>
                    <Card.Header><ImQuotesLeft style={{ fontSize: 20 }} /></Card.Header>
                    <Card.Body>
                      <div>
                        <div className='content-card'>
                          We are experts in adding a finishing touch to your space through furnishings and decorative elements to achieve  the required aesthetics, ambience, and aura within your space.
                        </div>
                        <div className='name'>Elena</div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>


                <div class='item'>
                  <Card className='testimonial-crd'>
                    <Card.Header><ImQuotesLeft style={{ fontSize: 20 }} /></Card.Header>
                    <Card.Body>
                      <div>
                        <div className='content-card'>
                          We are experts in adding a finishing touch to your space through furnishings and decorative elements to achieve  the required aesthetics, ambience, and aura within your space.
                        </div>
                        <div className='name'>Jill</div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </OwlCarousel>
            </div>
           
          </div>
        </Container>

      </div> */}

    </div>
  )
}

export default Services