import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./Ourprojects.css"

const KnowAboutMore = () => {
    const navigate = useNavigate()

    function movetothepage1(params) {
        
        navigate("/aboutus")
    }


    return (
        <div className=' responsive-mobile-change ' >
            <button onClick={movetothepage1} className='border' >
                Know More About Us
            </button>
        </div>
    )
}

export default KnowAboutMore