import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import GData from '../../Data/GData';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';

// import { Container } from 'react-bootstrap'.
import { FaHome } from "react-icons/fa";
import { MdOutlineMapsHomeWork } from "react-icons/md";
import { PiHospitalBold } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';

const AboutOurServices = () => {

    let Navi = useNavigate()

    function navigatepage1() {
        Navi("/service/Residential")
    }
    function navigatepage2() {
        Navi("/service/Office")

    }
    function navigatepage3() {
        Navi("/service/Hospitality")

    }




    return (
        <div data-aos="fade-up" data-aos-duration="2000">
            <div className="container-header-we-do">
                <div className="display-6 P-2 text-center"> Our Services   </div>
                <div class="separator  ">
                    <span>
                        <i class="fa fa-circle">*--*</i>
                    </span>
                </div>
            </div>

            <div className='Aboutus-ourservice-content  d-flex'>

                <div className="col-md-5" data-aos="fade-up" data-aos-duration="2000">

                    <Carousel fade  >
                        {GData.map((items) => (
                            <Carousel.Item key={items.id} className='carousel-img-aboutus-content'>
                                <img src={items.image} className='carousel-img-aboutus-content-img' alt={items.type} />
                            </Carousel.Item>
                        ))}
                    </Carousel>


                </div>
                <div className="col-md-7" data-aos="fade-up" data-aos-duration="2000">

                    <div className='main-right-ourservice ' >
                        <div className="main-right-ourservice-content p-1 m-1" data-aos="fade-down" data-aos-duration="2000" onClick={navigatepage1}>
                            <div className="main-right-ourservice-content-inntediv">
                                <div className='main-right-ourservice-content-inntediv-img-div'>
                                    <FaHome className='main-right-ourservice-content-inntediv-img' />
                                </div>
                            </div>
                            <div className="main-right-ourservice-content-inntediv d-flex justify-content-center flex-column ">
                                <h1 className='heading-ourservice-aboutus '>Residential Design</h1>
                                <p>Crafting homes that reflect your personality and enhance your lifestyle.</p>
                            </div>
                        </div>
                        <div className="main-right-ourservice-content p-1 m-1" data-aos="fade-up" data-aos-duration="2000" onClick={navigatepage2}>
                            <div className="main-right-ourservice-content-inntediv">
                                <div className='main-right-ourservice-content-inntediv-img-div'>
                                    <MdOutlineMapsHomeWork className='main-right-ourservice-content-inntediv-img' />
                                </div>
                            </div>
                            <div className="main-right-ourservice-content-inntediv d-flex justify-content-center flex-column ">
                                <h1 className='heading-ourservice-aboutus'>Office / Commercial Design </h1>
                                <p>Designing functional and inspiring workplaces that drive
                                    productivity.</p>
                            </div>
                        </div>
                        <div className="main-right-ourservice-content p-1 m-1" data-aos="fade-down" data-aos-duration="2000" onClick={navigatepage3}>
                            <div className="main-right-ourservice-content-inntediv">
                                <div className='main-right-ourservice-content-inntediv-img-div'>
                                    <PiHospitalBold className='main-right-ourservice-content-inntediv-img' />
                                </div>
                            </div>
                            <div className="main-right-ourservice-content-inntediv d-flex justify-content-center flex-column ">
                                <h1 className='heading-ourservice-aboutus'>Hospitality Design</h1>
                                <p>Creating inviting and memorable spaces that elevate guest experiences.</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutOurServices